<template>
  <section>
    <Observer name="fade-up" @enter="enter" @leave="leave">
      <div class="container-fluid">
        <video ref="video" v-if="item.type === 'video'" autoplay playsinline loop muted>
          <source ref="source" :data-src="item.video.url" type="video/mp4">
        </video>
        <img ref="picture" v-if="item.type === 'image'" :src="imageLowDefinition+ item.image.filter" :alt="item.image.alt || 'Zenith'"
             sizes="100vw"
        />
      </div>
    </Observer>
    <button v-if="item.type === 'video'" type="button" class="btn-autoPlay" :class="{'-paused': pauseVideo}" :aria-label="pauseVideo ? 'Play' : 'Pause'" @click="toggleVideo"></button>
  </section>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'
import ImageManager from 'vue-storefront-prismic-ssr/utilities/ImageManager'

export default {
  props: ['item', 'items', 'pagePosition', 'siblings-components'],
  mixins: [ReadyHook],
  name: 'video_gif',
  data () {
    return {
      pauseVideo: false,
      appeared: false
    }
  },
  computed: {
    imageUrl() {
      return this.item.image.url
    },
    imageLowDefinition() {
      return this.imageUrl.indexOf('.gif') > -1 ? '/assets/img/spacer.gif' : ImageManager.getLowDefinition(this.imageUrl, 400, 0.5636523266)
    }
  },
  methods: {
    enter() {
      if (!this.appeared) {
        this.appeared = true
        if (this.$refs.video) {
          this.$refs.source.setAttribute('src', this.$refs.source.dataset.src)
          this.$refs.source.removeAttribute('data-src')
          setTimeout(() => {
            this.$refs.video.load()
          }, 200)
        } else {
          if (this.$refs.picture) this.$refs.picture.srcset = ImageManager.getSrcSet(this.imageUrl, 1600, 200, 100, 0.5636523266)
        }
      } else if (this.$refs.video && this.$refs.video.paused) this.$refs.video.play()
    },
    leave() {
      if (this.$refs.video && !this.$refs.video.paused) this.$refs.video.pause()
    },
    toggleVideo () {
      if (this.pauseVideo) {
        this.$refs.video.play()
      } else {
        this.$refs.video.pause()
      }
      this.pauseVideo = !this.pauseVideo
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/core";

section {
  position: relative;
  margin: 10rem 0;

  video, img {
    width: 100%;
  }
}
</style>
